/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.TxPayload = (function() {

    /**
     * Properties of a TxPayload.
     * @exports ITxPayload
     * @interface ITxPayload
     * @property {number|null} [version] TxPayload version
     * @property {number|null} [flags] TxPayload flags
     * @property {Array.<Uint8Array>|null} [sha2] TxPayload sha2
     * @property {Array.<Uint8Array>|null} [sha3] TxPayload sha3
     * @property {Array.<string>|null} [uri] TxPayload uri
     * @property {Array.<string>|null} [http] TxPayload http
     * @property {Array.<string>|null} [https] TxPayload https
     * @property {Array.<Uint8Array>|null} [ipfs] TxPayload ipfs
     * @property {Array.<Uint8Array>|null} [magnetSha1] TxPayload magnetSha1
     * @property {Array.<Uint8Array>|null} [magnetBtih] TxPayload magnetBtih
     */

    /**
     * Constructs a new TxPayload.
     * @exports TxPayload
     * @classdesc Represents a TxPayload.
     * @implements ITxPayload
     * @constructor
     * @param {ITxPayload=} [properties] Properties to set
     */
    function TxPayload(properties) {
        this.sha2 = [];
        this.sha3 = [];
        this.uri = [];
        this.http = [];
        this.https = [];
        this.ipfs = [];
        this.magnetSha1 = [];
        this.magnetBtih = [];
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * TxPayload version.
     * @member {number} version
     * @memberof TxPayload
     * @instance
     */
    TxPayload.prototype.version = 0;

    /**
     * TxPayload flags.
     * @member {number} flags
     * @memberof TxPayload
     * @instance
     */
    TxPayload.prototype.flags = 0;

    /**
     * TxPayload sha2.
     * @member {Array.<Uint8Array>} sha2
     * @memberof TxPayload
     * @instance
     */
    TxPayload.prototype.sha2 = $util.emptyArray;

    /**
     * TxPayload sha3.
     * @member {Array.<Uint8Array>} sha3
     * @memberof TxPayload
     * @instance
     */
    TxPayload.prototype.sha3 = $util.emptyArray;

    /**
     * TxPayload uri.
     * @member {Array.<string>} uri
     * @memberof TxPayload
     * @instance
     */
    TxPayload.prototype.uri = $util.emptyArray;

    /**
     * TxPayload http.
     * @member {Array.<string>} http
     * @memberof TxPayload
     * @instance
     */
    TxPayload.prototype.http = $util.emptyArray;

    /**
     * TxPayload https.
     * @member {Array.<string>} https
     * @memberof TxPayload
     * @instance
     */
    TxPayload.prototype.https = $util.emptyArray;

    /**
     * TxPayload ipfs.
     * @member {Array.<Uint8Array>} ipfs
     * @memberof TxPayload
     * @instance
     */
    TxPayload.prototype.ipfs = $util.emptyArray;

    /**
     * TxPayload magnetSha1.
     * @member {Array.<Uint8Array>} magnetSha1
     * @memberof TxPayload
     * @instance
     */
    TxPayload.prototype.magnetSha1 = $util.emptyArray;

    /**
     * TxPayload magnetBtih.
     * @member {Array.<Uint8Array>} magnetBtih
     * @memberof TxPayload
     * @instance
     */
    TxPayload.prototype.magnetBtih = $util.emptyArray;

    /**
     * Creates a new TxPayload instance using the specified properties.
     * @function create
     * @memberof TxPayload
     * @static
     * @param {ITxPayload=} [properties] Properties to set
     * @returns {TxPayload} TxPayload instance
     */
    TxPayload.create = function create(properties) {
        return new TxPayload(properties);
    };

    /**
     * Encodes the specified TxPayload message. Does not implicitly {@link TxPayload.verify|verify} messages.
     * @function encode
     * @memberof TxPayload
     * @static
     * @param {ITxPayload} message TxPayload message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    TxPayload.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.version != null && message.hasOwnProperty("version"))
            writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.version);
        if (message.flags != null && message.hasOwnProperty("flags"))
            writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.flags);
        if (message.sha2 != null && message.sha2.length)
            for (var i = 0; i < message.sha2.length; ++i)
                writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.sha2[i]);
        if (message.sha3 != null && message.sha3.length)
            for (var i = 0; i < message.sha3.length; ++i)
                writer.uint32(/* id 4, wireType 2 =*/34).bytes(message.sha3[i]);
        if (message.uri != null && message.uri.length)
            for (var i = 0; i < message.uri.length; ++i)
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.uri[i]);
        if (message.http != null && message.http.length)
            for (var i = 0; i < message.http.length; ++i)
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.http[i]);
        if (message.https != null && message.https.length)
            for (var i = 0; i < message.https.length; ++i)
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.https[i]);
        if (message.ipfs != null && message.ipfs.length)
            for (var i = 0; i < message.ipfs.length; ++i)
                writer.uint32(/* id 8, wireType 2 =*/66).bytes(message.ipfs[i]);
        if (message.magnetSha1 != null && message.magnetSha1.length)
            for (var i = 0; i < message.magnetSha1.length; ++i)
                writer.uint32(/* id 9, wireType 2 =*/74).bytes(message.magnetSha1[i]);
        if (message.magnetBtih != null && message.magnetBtih.length)
            for (var i = 0; i < message.magnetBtih.length; ++i)
                writer.uint32(/* id 10, wireType 2 =*/82).bytes(message.magnetBtih[i]);
        return writer;
    };

    /**
     * Encodes the specified TxPayload message, length delimited. Does not implicitly {@link TxPayload.verify|verify} messages.
     * @function encodeDelimited
     * @memberof TxPayload
     * @static
     * @param {ITxPayload} message TxPayload message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    TxPayload.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a TxPayload message from the specified reader or buffer.
     * @function decode
     * @memberof TxPayload
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {TxPayload} TxPayload
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    TxPayload.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.TxPayload();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.version = reader.uint32();
                break;
            case 2:
                message.flags = reader.uint32();
                break;
            case 3:
                if (!(message.sha2 && message.sha2.length))
                    message.sha2 = [];
                message.sha2.push(reader.bytes());
                break;
            case 4:
                if (!(message.sha3 && message.sha3.length))
                    message.sha3 = [];
                message.sha3.push(reader.bytes());
                break;
            case 5:
                if (!(message.uri && message.uri.length))
                    message.uri = [];
                message.uri.push(reader.string());
                break;
            case 6:
                if (!(message.http && message.http.length))
                    message.http = [];
                message.http.push(reader.string());
                break;
            case 7:
                if (!(message.https && message.https.length))
                    message.https = [];
                message.https.push(reader.string());
                break;
            case 8:
                if (!(message.ipfs && message.ipfs.length))
                    message.ipfs = [];
                message.ipfs.push(reader.bytes());
                break;
            case 9:
                if (!(message.magnetSha1 && message.magnetSha1.length))
                    message.magnetSha1 = [];
                message.magnetSha1.push(reader.bytes());
                break;
            case 10:
                if (!(message.magnetBtih && message.magnetBtih.length))
                    message.magnetBtih = [];
                message.magnetBtih.push(reader.bytes());
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a TxPayload message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof TxPayload
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {TxPayload} TxPayload
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    TxPayload.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a TxPayload message.
     * @function verify
     * @memberof TxPayload
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    TxPayload.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.version != null && message.hasOwnProperty("version"))
            if (!$util.isInteger(message.version))
                return "version: integer expected";
        if (message.flags != null && message.hasOwnProperty("flags"))
            if (!$util.isInteger(message.flags))
                return "flags: integer expected";
        if (message.sha2 != null && message.hasOwnProperty("sha2")) {
            if (!Array.isArray(message.sha2))
                return "sha2: array expected";
            for (var i = 0; i < message.sha2.length; ++i)
                if (!(message.sha2[i] && typeof message.sha2[i].length === "number" || $util.isString(message.sha2[i])))
                    return "sha2: buffer[] expected";
        }
        if (message.sha3 != null && message.hasOwnProperty("sha3")) {
            if (!Array.isArray(message.sha3))
                return "sha3: array expected";
            for (var i = 0; i < message.sha3.length; ++i)
                if (!(message.sha3[i] && typeof message.sha3[i].length === "number" || $util.isString(message.sha3[i])))
                    return "sha3: buffer[] expected";
        }
        if (message.uri != null && message.hasOwnProperty("uri")) {
            if (!Array.isArray(message.uri))
                return "uri: array expected";
            for (var i = 0; i < message.uri.length; ++i)
                if (!$util.isString(message.uri[i]))
                    return "uri: string[] expected";
        }
        if (message.http != null && message.hasOwnProperty("http")) {
            if (!Array.isArray(message.http))
                return "http: array expected";
            for (var i = 0; i < message.http.length; ++i)
                if (!$util.isString(message.http[i]))
                    return "http: string[] expected";
        }
        if (message.https != null && message.hasOwnProperty("https")) {
            if (!Array.isArray(message.https))
                return "https: array expected";
            for (var i = 0; i < message.https.length; ++i)
                if (!$util.isString(message.https[i]))
                    return "https: string[] expected";
        }
        if (message.ipfs != null && message.hasOwnProperty("ipfs")) {
            if (!Array.isArray(message.ipfs))
                return "ipfs: array expected";
            for (var i = 0; i < message.ipfs.length; ++i)
                if (!(message.ipfs[i] && typeof message.ipfs[i].length === "number" || $util.isString(message.ipfs[i])))
                    return "ipfs: buffer[] expected";
        }
        if (message.magnetSha1 != null && message.hasOwnProperty("magnetSha1")) {
            if (!Array.isArray(message.magnetSha1))
                return "magnetSha1: array expected";
            for (var i = 0; i < message.magnetSha1.length; ++i)
                if (!(message.magnetSha1[i] && typeof message.magnetSha1[i].length === "number" || $util.isString(message.magnetSha1[i])))
                    return "magnetSha1: buffer[] expected";
        }
        if (message.magnetBtih != null && message.hasOwnProperty("magnetBtih")) {
            if (!Array.isArray(message.magnetBtih))
                return "magnetBtih: array expected";
            for (var i = 0; i < message.magnetBtih.length; ++i)
                if (!(message.magnetBtih[i] && typeof message.magnetBtih[i].length === "number" || $util.isString(message.magnetBtih[i])))
                    return "magnetBtih: buffer[] expected";
        }
        return null;
    };

    /**
     * Creates a TxPayload message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof TxPayload
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {TxPayload} TxPayload
     */
    TxPayload.fromObject = function fromObject(object) {
        if (object instanceof $root.TxPayload)
            return object;
        var message = new $root.TxPayload();
        if (object.version != null)
            message.version = object.version >>> 0;
        if (object.flags != null)
            message.flags = object.flags >>> 0;
        if (object.sha2) {
            if (!Array.isArray(object.sha2))
                throw TypeError(".TxPayload.sha2: array expected");
            message.sha2 = [];
            for (var i = 0; i < object.sha2.length; ++i)
                if (typeof object.sha2[i] === "string")
                    $util.base64.decode(object.sha2[i], message.sha2[i] = $util.newBuffer($util.base64.length(object.sha2[i])), 0);
                else if (object.sha2[i].length)
                    message.sha2[i] = object.sha2[i];
        }
        if (object.sha3) {
            if (!Array.isArray(object.sha3))
                throw TypeError(".TxPayload.sha3: array expected");
            message.sha3 = [];
            for (var i = 0; i < object.sha3.length; ++i)
                if (typeof object.sha3[i] === "string")
                    $util.base64.decode(object.sha3[i], message.sha3[i] = $util.newBuffer($util.base64.length(object.sha3[i])), 0);
                else if (object.sha3[i].length)
                    message.sha3[i] = object.sha3[i];
        }
        if (object.uri) {
            if (!Array.isArray(object.uri))
                throw TypeError(".TxPayload.uri: array expected");
            message.uri = [];
            for (var i = 0; i < object.uri.length; ++i)
                message.uri[i] = String(object.uri[i]);
        }
        if (object.http) {
            if (!Array.isArray(object.http))
                throw TypeError(".TxPayload.http: array expected");
            message.http = [];
            for (var i = 0; i < object.http.length; ++i)
                message.http[i] = String(object.http[i]);
        }
        if (object.https) {
            if (!Array.isArray(object.https))
                throw TypeError(".TxPayload.https: array expected");
            message.https = [];
            for (var i = 0; i < object.https.length; ++i)
                message.https[i] = String(object.https[i]);
        }
        if (object.ipfs) {
            if (!Array.isArray(object.ipfs))
                throw TypeError(".TxPayload.ipfs: array expected");
            message.ipfs = [];
            for (var i = 0; i < object.ipfs.length; ++i)
                if (typeof object.ipfs[i] === "string")
                    $util.base64.decode(object.ipfs[i], message.ipfs[i] = $util.newBuffer($util.base64.length(object.ipfs[i])), 0);
                else if (object.ipfs[i].length)
                    message.ipfs[i] = object.ipfs[i];
        }
        if (object.magnetSha1) {
            if (!Array.isArray(object.magnetSha1))
                throw TypeError(".TxPayload.magnetSha1: array expected");
            message.magnetSha1 = [];
            for (var i = 0; i < object.magnetSha1.length; ++i)
                if (typeof object.magnetSha1[i] === "string")
                    $util.base64.decode(object.magnetSha1[i], message.magnetSha1[i] = $util.newBuffer($util.base64.length(object.magnetSha1[i])), 0);
                else if (object.magnetSha1[i].length)
                    message.magnetSha1[i] = object.magnetSha1[i];
        }
        if (object.magnetBtih) {
            if (!Array.isArray(object.magnetBtih))
                throw TypeError(".TxPayload.magnetBtih: array expected");
            message.magnetBtih = [];
            for (var i = 0; i < object.magnetBtih.length; ++i)
                if (typeof object.magnetBtih[i] === "string")
                    $util.base64.decode(object.magnetBtih[i], message.magnetBtih[i] = $util.newBuffer($util.base64.length(object.magnetBtih[i])), 0);
                else if (object.magnetBtih[i].length)
                    message.magnetBtih[i] = object.magnetBtih[i];
        }
        return message;
    };

    /**
     * Creates a plain object from a TxPayload message. Also converts values to other types if specified.
     * @function toObject
     * @memberof TxPayload
     * @static
     * @param {TxPayload} message TxPayload
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    TxPayload.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.arrays || options.defaults) {
            object.sha2 = [];
            object.sha3 = [];
            object.uri = [];
            object.http = [];
            object.https = [];
            object.ipfs = [];
            object.magnetSha1 = [];
            object.magnetBtih = [];
        }
        if (options.defaults) {
            object.version = 0;
            object.flags = 0;
        }
        if (message.version != null && message.hasOwnProperty("version"))
            object.version = message.version;
        if (message.flags != null && message.hasOwnProperty("flags"))
            object.flags = message.flags;
        if (message.sha2 && message.sha2.length) {
            object.sha2 = [];
            for (var j = 0; j < message.sha2.length; ++j)
                object.sha2[j] = options.bytes === String ? $util.base64.encode(message.sha2[j], 0, message.sha2[j].length) : options.bytes === Array ? Array.prototype.slice.call(message.sha2[j]) : message.sha2[j];
        }
        if (message.sha3 && message.sha3.length) {
            object.sha3 = [];
            for (var j = 0; j < message.sha3.length; ++j)
                object.sha3[j] = options.bytes === String ? $util.base64.encode(message.sha3[j], 0, message.sha3[j].length) : options.bytes === Array ? Array.prototype.slice.call(message.sha3[j]) : message.sha3[j];
        }
        if (message.uri && message.uri.length) {
            object.uri = [];
            for (var j = 0; j < message.uri.length; ++j)
                object.uri[j] = message.uri[j];
        }
        if (message.http && message.http.length) {
            object.http = [];
            for (var j = 0; j < message.http.length; ++j)
                object.http[j] = message.http[j];
        }
        if (message.https && message.https.length) {
            object.https = [];
            for (var j = 0; j < message.https.length; ++j)
                object.https[j] = message.https[j];
        }
        if (message.ipfs && message.ipfs.length) {
            object.ipfs = [];
            for (var j = 0; j < message.ipfs.length; ++j)
                object.ipfs[j] = options.bytes === String ? $util.base64.encode(message.ipfs[j], 0, message.ipfs[j].length) : options.bytes === Array ? Array.prototype.slice.call(message.ipfs[j]) : message.ipfs[j];
        }
        if (message.magnetSha1 && message.magnetSha1.length) {
            object.magnetSha1 = [];
            for (var j = 0; j < message.magnetSha1.length; ++j)
                object.magnetSha1[j] = options.bytes === String ? $util.base64.encode(message.magnetSha1[j], 0, message.magnetSha1[j].length) : options.bytes === Array ? Array.prototype.slice.call(message.magnetSha1[j]) : message.magnetSha1[j];
        }
        if (message.magnetBtih && message.magnetBtih.length) {
            object.magnetBtih = [];
            for (var j = 0; j < message.magnetBtih.length; ++j)
                object.magnetBtih[j] = options.bytes === String ? $util.base64.encode(message.magnetBtih[j], 0, message.magnetBtih[j].length) : options.bytes === Array ? Array.prototype.slice.call(message.magnetBtih[j]) : message.magnetBtih[j];
        }
        return object;
    };

    /**
     * Converts this TxPayload to JSON.
     * @function toJSON
     * @memberof TxPayload
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    TxPayload.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Flags enum.
     * @name TxPayload.Flags
     * @enum {string}
     * @property {number} CONTENT_UPDATE=1 CONTENT_UPDATE value
     * @property {number} OWNERSHIP_TRANSFER=2 OWNERSHIP_TRANSFER value
     */
    TxPayload.Flags = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[1] = "CONTENT_UPDATE"] = 1;
        values[valuesById[2] = "OWNERSHIP_TRANSFER"] = 2;
        return values;
    })();

    return TxPayload;
})();

module.exports = $root;
